<template>
  <div class="stand-list">
    <Action-panel :isReady="isPageReady">
      <Button-base
        v-if="ui.create"
        :type="2"
        :text="translations.labels.button_add_stand"
        :hoverInfo="translations.labels.button_add_stand_hover"
        :onClick="newItem"
      />
    </Action-panel>

    <TableList
      :is-ready="isPageReady"
      :headers="headers"
      :items="model"
       />
  </div>
</template>

<script>
import Vue from "vue";
import { Utility } from "@/lib/Utility";
import Component from "vue-class-component";
import Container from "@/components/dom/Container";
import ActionPanel from "@/components/panel/ActionPanel";
import InnerPanel from "@/components/panel/InnerPanel";
import TablePanel from "@/components/panel/TablePanel";
import ButtonBase from "@/components/dom/ButtonBase";
import SvgIcon from "@/components/dom/SvgIcon";
import Round from "@/components/dom/Round";
import TableList from "@/components/table/TableList";

@Component({
   components: {
    ActionPanel,
    InnerPanel,
    TablePanel,
    ButtonBase,
    SvgIcon,
    Round,
    Container,
    TableList
  },
  inject: {
    uiService: "uiService",
    standService: "standService",
    pavilionService: "pavilionService",
    toastService: "toastService",
  }
})

export default class StandList extends Vue {
  isPageReady = false;
  model = [];
  pavilions = [];
  search = "";
  ui = {};

  async prepareList () {
    let stands = await this.standService.list();
    stands.forEach(stand => {
      const pavilion = this.pavilions.filter(p => p.id == stand.idPavilion);
      if (pavilion.length == 0) {
        stand.pavilion = "---";
        stand.idEvent = null;
      } else {
        stand.pavilion = pavilion[0].code;
        stand.idEvent = pavilion[0].idEvent;
      }
    });
    return stands;
  }

  async init () {
    try {
      this.ui = await this.uiService.ui("stand");
      this.pavilions = await this.pavilionService.list();
      this.model = await this.prepareList();
      this.isPageReady = true;
    } catch (error) {
      this.toastService.error(this.translations.errors.somethingWrong);
    }
  }

  toastSuccessLabel (isEnabled) {
    return isEnabled
      ? this.toastService.success(this.translations.success.stand_disable)
      : this.toastService.success(this.translations.success.stand_enable);
  }

  async toggleEnabled(idStand, isEnabled) {
    const status = isEnabled ? "disable" : "enable";
    this.isPageReady = false;

    try {
      await this.standService.setEnabled(idStand, status);
      this.model = await this.prepareList();

      this.toastSuccessLabel(isEnabled);
      this.isPageReady = true;
    } catch (error) {
      this.isPageReady = true;
      this.toastService.error(this.translations.errors.somethingWrong);
    }
  }

  async togglePublished(idStand, isPublished) {
    const status = isPublished ? "unpublish" : "publish";
    this.isPageReady = false;

    try {
      await this.standService.setPublished(idStand, status);
      this.model = await this.prepareList();

      this.toastSuccessLabel(isPublished);
      this.isPageReady = true;
    } catch (error) {
      this.isPageReady = true;
      this.toastService.error(this.translations.errors.somethingWrong);
    }
  }

  newItem() {
    this.$router.push("/stand/new");
  }

  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  get headers() {
    const childElements = (this?.ui?.childElements ?? []);
    return [
      {
        text: this.translations.labels.stand_table_code,
        value: "code"
      },
      {
        text: this.translations.labels.stand_table_pavilion,
        value: "pavilion"
      },
      // show links only if ui.childElements contains links
      ...Utility.addIf(
        {
          text: this.translations.labels.links,
          value: "link",
          type: "link",
          icon: "mdi-link-variant",
          formatValue: (item) => {
            return `/link/${item.id}/Stand`;
          },
        },
        childElements.includes("links")
      ),
      // show livesessions only if ui.childElements contains livesessions
      ...Utility.addIf(
        {
          text: this.translations.labels.liveSession,
          value: "liveSession",
          type: "link",
          icon: "mdi-presentation-play",
          formatValue: (item) => {
            return `/live-session/list/${item.id}/Stand`;
          },
        },
        childElements.includes("livesessions")
      ),
      // // show owners only if ui.childElements contains owners
      ...Utility.addIf(
        {
          text: "Owners",
          value: "owners",
          type: "link",
          icon: "mdi-presentation-play",
          formatValue: (item) => {
            return `/owners/${item.id}`;
          },
        },
        childElements.includes("owners")
      ),
      // show infoboxes only if ui.childElements contains infoboxes
      ...Utility.addIf(
        {
          text: this.translations.labels.infoBox,
          value: "infoBox",
          type: "link",
          icon: "mdi-information",
          formatValue: (item) => {
            return `/info-box/list/${item.id}`;
          },
        },
        childElements.includes("infoboxes")
      ),
      // show files, videos and htmlContents only if ui.childElements contains contents
      ...Utility.addIf(
        {
          text: this.translations.labels.files,
          value: "file",
          type: "link",
          icon: "mdi-file",
          buttonType: 2,
          formatValue: (item) => {
            return `/content/list/${item.id}/File`;
          },
        },
        childElements.includes("contents")
      ),
      ...Utility.addIf(
        {
          text: this.translations.labels.videos,
          value: "video",
          type: "link",
          icon: "mdi-youtube",
          buttonType: 2,
          formatValue: (item) => {
            return `/content/list/${item.id}/Video`;
          },
        },
        childElements.includes("contents")
      ),
      ...Utility.addIf(
        {
          text: this.translations.labels.htmlContents,
          value: "htmlcontent",
          type: "link",
          icon: "mdi-web",
          buttonType: 2,
          formatValue: (item) => {
            return `/content/list/${item.id}/HtmlContent`;
          },
        },
        childElements.includes("contents")
      ),
      // show detail only if ui.read is true
      ...Utility.addIf(
        {
          text: this.translations.labels.table_detail,
          value: "detail",
          type: "link",
          icon: "mdi-feature-search-outline",
          formatValue: (item) => {
            return `/stand/detail/${item.id}`;
          },
        },
        this.ui.read
      ),
      // show edit only if ui.update is true
      ...Utility.addIf(
        {
          text: this.translations.labels.table_clone,
          value: "clone",
          type: "link",
          formatValue: (item) => {
            return `/stand/clone/${item.id}`;
          },
          icon: "mdi-content-duplicate",
          buttonType: 2,
        },
        this.ui.duplicate
      ),
      // show edit only if ui.duplicate is true
      ...Utility.addIf(
        {
          text: this.translations.labels.table_edit,
          value: "edit",
          type: "link",
          formatValue: (item) => {
            return `/stand/edit/${item.id}`;
          },
          icon: "mdi-pencil",
          buttonType: 2,
        },
        this.ui.update
      ),
      // show enable/disable only if ui.enableDisable is true
      ...Utility.addIf(
        {
          text: this.translations.labels.table_enable,
          value: "enable",
          type: "switch",
          itemValue: (item) => {
            return item.isEnabled;
          },
          onAction: (item) => {
            this.toggleEnabled(item.id, item.isEnabled);
          },
        },
        this.ui.enableDisable
      ),
      {
        text: this.translations.labels.table_publish,
        value: "publish",
        type: "switch",
        itemValue: (item) => {
          return item.isPublished;
        },
        onAction: (item) => {
          this.togglePublished(item.id, item.isPublished);
        },
      }
    ];
  }

  mounted () {
    this.init();
    this.$store.commit(
      "SET_SECTION_TITLE",
      this.translations.pageTitles.stands_list
    );
  }
}

</script>
<style lang="scss" scoped>
  .stand-list {

    &__btn-icon {
      width: 40px;
      height: 40px;
      display: inline-block;
    }

    .btn-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 20px 30px 0 30px;
        margin: 15px 30px 0 30px;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked+.slider {
        background-color: #2196F3;
    }

    input:focus+.slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}
</style>
